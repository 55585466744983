import Script from 'next/script';
import * as React from 'react';
import { getRuntimeConfig } from 'environment/next.publicRuntime.config';

export const GoogleAnalytics = (): React.ReactElement | null => {
    const googleAnalyticsId = getRuntimeConfig('googleAnalyticsId');

    if (!googleAnalyticsId) {
        return null;
    }

    return (
        <>
            <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`} />
            <Script id="ga-analytics">
                {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${googleAnalyticsId}');
          `}
            </Script>
        </>
    );
};
