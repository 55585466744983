import { css, Global } from '@emotion/react';

const globalCss = css({
    a: {
        color: 'inherit',
        textDecoration: 'none',
    },
});

export const GlobalStyles = () => <Global styles={globalCss} />;
