/**
 * MUI/Emotion/NextJS SSR rendering support
 * https://github.com/mui-org/material-ui/blob/857c7ad547207b647094c97be69385c7d921ddc2/examples/nextjs-with-typescript/pages/_app.tsx
 */
import { CacheProvider, type EmotionCache } from '@emotion/react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import 'core-js/features/array/at';
import { type AppProps } from 'next/app';
import Head from 'next/head';
import * as React from 'react';
import { CloudflareAnalytics } from 'components/CloudflareAnalytics';
import { GoogleAnalytics } from 'components/GoogleAnalytics';
import { LocalizationProvider } from 'components/LocalizationProvider';
import { OneTrustCookieScripts } from 'components/OneTrustCookieBanner';
import createEmotionCache from 'helpers/createEmotionCache';
import { GlobalStyles } from 'styles/global';
import { theme } from 'styles/mui-theme';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export type MyAppProps = AppProps & {
    emotionCache?: EmotionCache;
};

/* eslint-disable @next/next/no-page-custom-font */
function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
    return (
        <LocalizationProvider>
            <CacheProvider value={emotionCache}>
                <Head>
                    <title>Triton Digital Podcast Rankers</title>
                    <meta
                        name="description"
                        content="Triton Digital's Podcast Rankers list the Top Podcasts in a particular region, as measured by Triton's Podcast Metrics measurement service."
                    />
                    <link rel="icon" href="/favicon.ico" />
                </Head>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyles />
                    <Component {...pageProps} />
                    <OneTrustCookieScripts />
                    <CloudflareAnalytics />
                    <GoogleAnalytics />
                </ThemeProvider>
            </CacheProvider>
        </LocalizationProvider>
    );
}

export default MyApp;
