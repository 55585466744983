import { createTheme } from '@mui/material/styles';

type TritonColors = {
    skyBlue: string;
    paleBlue: string;
    veryLightBlue: string;
    blue: string;
    gray: string;
    yellow: string;
    darkBlue: string;
};

declare module '@mui/material/styles' {
    interface Theme {
        tritonColors: TritonColors;
    }
    interface ThemeOptions {
        tritonColors: TritonColors;
    }
}

const tritonColors: TritonColors = {
    skyBlue: '#43a8df',
    paleBlue: '#74cae4',
    veryLightBlue: '#eef9fc',
    blue: '#015a9c',
    gray: '#6d6e70',
    yellow: '#f0d824',
    darkBlue: '#231e50',
};

export const theme = createTheme({
    tritonColors,
    palette: {
        primary: {
            main: tritonColors.blue,
        },
        secondary: {
            main: tritonColors.darkBlue,
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
        },
    },
});
