import Script from 'next/script';
import * as React from 'react';
import { getRuntimeConfig } from 'environment/next.publicRuntime.config';

// Partial types for the OneTrust banner SDK https://community.cookiepro.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643?language=en_US
type OneTrust = {
    GetDomainData: () => {
        CookieSettingButtonText: string;
    };
    ToggleInfoDisplay: () => void;
};

declare global {
    interface Window {
        // OneTrust loads asynchronously - it may not be loaded by the time React renders.
        // we expose an array-like interface where `oneTrustOnLoaded.push` can be used to push
        // a callback that will be executed if and when OneTrust has loaded.
        oneTrustOnLoaded?: {
            push: (listener: (oneTrust: OneTrust) => void) => void;
        };
    }
}

export const OneTrustCookieScripts = (): React.ReactElement | null => {
    const oneTrustId = getRuntimeConfig('oneTrustId');

    if (!oneTrustId) {
        return null;
    }

    return (
        // This component is placed in _app so is rendered on the first render where it will work
        // Unfortunately this script cannot be placed in _document because fragments do not work there
        /* eslint-disable @next/next/no-before-interactive-script-outside-document */
        <>
            <Script
                src={`https://cdn.cookielaw.org/consent/${oneTrustId}/OtAutoBlock.js`}
                // force script to load in the head
                defer={false}
                strategy="beforeInteractive"
            />
            <Script
                src={`https://cdn.cookielaw.org/consent/${oneTrustId}/otSDKStub.js`}
                data-domain-script={oneTrustId}
                // force script to load in the head
                defer={false}
                strategy="beforeInteractive"
            />
            {/* 
                The OneTrust JS SDK triggers OptanonWrapper callback function after banner script execution 
                https://community.cookiepro.com/s/article/UUID-29158b4e-22f6-0067-aa36-94f3b8cf3561?language=en_US  
            */}
            <Script strategy="beforeInteractive" id="onetrust-callback">
                {`
                    window.oneTrustOnLoaded = [];

                    function OptanonWrapper() {
                        if (window.oneTrustOnLoaded.forEach) {
                            window.oneTrustOnLoaded.forEach(function (listener) {
                                listener(window.OneTrust);
                            });

                            window.oneTrustOnLoaded = {
                                push: function (listener) {
                                    listener(window.OneTrust);
                                }
                            };
                        }
                    }
                `}
            </Script>
        </>
        /* eslint-enable @next/next/no-before-interactive-script-outside-document */
    );
};

export const useOneTrustSettingsLink = () => {
    const [state, setState] = React.useState<{ label: string; onClick: () => void } | null>(null);

    React.useEffect(() => {
        if (!window.oneTrustOnLoaded) {
            if (process.env.NODE_ENV === 'development') {
                setState({
                    label: 'Cookie Settings (DEV)',
                    onClick: () => {
                        alert('Not implemented in dev');
                    },
                });
            }

            return;
        }

        window.oneTrustOnLoaded.push((oneTrust) => {
            setState({
                label: oneTrust.GetDomainData().CookieSettingButtonText,
                onClick: () => {
                    oneTrust.ToggleInfoDisplay();
                },
            });
        });
    }, []);

    return state;
};

/**
 * OneTrust SDK has auto-blocking behaviour which stops certain scripts from loading if user rejects cookies https://community.cookiepro.com/s/article/UUID-c5122557-2070-65cb-2612-f2752c0cc4aa?language=en_US
 * However this seems to have a false-positive with the app's own scripts
 * We can explicitly opt-out the app's own <script> resources by adding "data-ot-ignore" attribute https://my.onetrust.com/articles/en_US/Knowledge/UUID-71e7d0a8-03d8-e272-e683-72cadded2ecf
 */
export function patchAppScriptOneTrustIgnore(script: JSX.Element) {
    return React.cloneElement(script, {
        'data-ot-ignore': '',
    });
}
