import { type Options } from '@sentry/types';
import { getRuntimeConfig } from 'environment/next.publicRuntime.config';

const sentryRelease = process.env['NEXT_PUBLIC_VERSION'];

export const sentryConfigShared: Options = {
    dsn: getRuntimeConfig('sentryDsn'),
    environment: getRuntimeConfig('sentryEnvironment'),
    release: sentryRelease,
    // attach stack trace to captureMessage
    attachStacktrace: true,
};
