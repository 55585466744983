import getConfig from 'next/config';
import runtimeConfig from 'environment/runtimeConfig';

/**
 * Types for NextJS runtime configuration
 * This is defined separately because the `runtimeConfig.js` must be a JavaScript file for `next.config.js`
 * See `runtimeConfig.js` to inject value
 */
export type NextPublicRuntimeConfig = typeof runtimeConfig;

export function getRuntimeConfig(key: keyof NextPublicRuntimeConfig) {
    const isServer = typeof window === 'undefined';

    /**
     * If running server-side, return using the environment variable directly
     */
    if (isServer) {
        return runtimeConfig[key];
    }

    const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: NextPublicRuntimeConfig };

    return publicRuntimeConfig[key];
}

export function getBooleanRuntimeConfig(key: keyof NextPublicRuntimeConfig): boolean | undefined {
    const config = getRuntimeConfig(key);

    if (config === undefined) {
        return undefined;
    }

    const trimmedValue = config.trim().toLowerCase();

    switch (trimmedValue) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            throw new Error(`Unexpected value ${config} for runtime config ${key}, expected either true or false`);
    }
}

export function getRuntimeConfigRequired(key: keyof NextPublicRuntimeConfig) {
    const value = getRuntimeConfig(key);

    if (value === undefined) {
        throw new Error(`Runtime config "${key}" is not defined`);
    }

    return value;
}
