import Script from 'next/script';
import * as React from 'react';
import { getRuntimeConfig } from 'environment/next.publicRuntime.config';

export const CloudflareAnalytics = (): React.ReactElement | null => {
    const cloudflareAnalyticsToken = getRuntimeConfig('cloudflareAnalyticsToken');

    if (!cloudflareAnalyticsToken) {
        return null;
    }

    return (
        <Script
            src="https://static.cloudflareinsights.com/beacon.min.js"
            data-cf-beacon={`{"token": "${cloudflareAnalyticsToken}"}`}
        />
    );
};
