import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { fn } from 'helpers/fn';
import { getFirstQueryParam } from 'helpers/getFirstQueryParam';
import { type Locale, defaultLocale, messagesByLocale } from 'models/locale';

// simulate the NEXT_LOCALE cookie using client-side cookie parsing
const COOKIE_LOCALE_NAME = 'NEXT_LOCALE';

// the name of the URL parameter to override the language
const URL_PARAM = 'lang';

type LocalizationContext = {
    locale: Locale;
    setLocale: (locale: Locale) => void;
};

const LocalizationContext = React.createContext<LocalizationContext | null>(null);

export function useLocalizationContext() {
    const context = React.useContext(LocalizationContext);

    if (!context) {
        throw Error('LocalizationContext not available');
    }

    return context;
}

export function useLocale() {
    const { locale } = useLocalizationContext();

    return locale;
}

export const LocalizationProvider = ({ children }: React.PropsWithChildren<unknown>) => {
    const router = useRouter();
    const langQueryParameter = React.useMemo(() => getFirstQueryParam(router.query[URL_PARAM]), [router.query]);

    const [cookieValue, setCookieValue] = React.useState<string>();
    const [navigatorLanguage, setNavigatorLanguage] = React.useState<string>();

    // read client-side browser values in a React.useEffect
    // avoids NextJS/React rehydration complaining about server/client values mismatch
    React.useEffect(() => {
        // handle getting the cookie value
        setCookieValue(getCookieValue());

        // get browser navigator language
        setNavigatorLanguage(navigator.language);
    }, []);

    const locale = React.useMemo(
        () => getLocaleWithFallback(langQueryParameter, cookieValue, navigatorLanguage),
        [cookieValue, langQueryParameter, navigatorLanguage],
    );

    const setLocale = React.useCallback(
        (locale: string) => {
            // remove any existing URL parameter override
            if (router.query[URL_PARAM]) {
                // make a copy of the query object
                const queryWithoutLang = { ...router.query };

                // remove the lang entry
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete queryWithoutLang[URL_PARAM];

                // replace the route, keep all other queries
                void router.replace({
                    pathname: router.pathname,
                    query: queryWithoutLang,
                });
            }

            // update state
            setCookieValue(locale);
            // store cookie
            setCookie(COOKIE_LOCALE_NAME, locale);
        },
        [router],
    );

    const contextValue = React.useMemo((): LocalizationContext => {
        return {
            locale,
            setLocale,
        };
    }, [locale, setLocale]);

    // set <html lang="">
    React.useEffect(() => {
        document.documentElement.setAttribute('lang', contextValue.locale);
    }, [contextValue.locale]);

    return (
        <LocalizationContext.Provider value={contextValue}>
            <IntlProvider messages={messagesByLocale[locale]} locale={locale} defaultLocale={defaultLocale}>
                {children}
            </IntlProvider>
        </LocalizationContext.Provider>
    );
};

/**
 * Function to get the cookie value
 */
export function getCookieValue() {
    if (!navigator.cookieEnabled) {
        return undefined;
    }

    const cookieValue = fn(() => {
        try {
            return getCookie(COOKIE_LOCALE_NAME);
        } catch (error) {
            console.error('Error while reading cookie', error);

            return;
        }
    });

    return typeof cookieValue === 'string' ? cookieValue : undefined;
}

/**
 * Function to get the desired locale
 * Checks locale in the sequence: query param, cookie, navigator, default
 */
function getLocaleWithFallback(
    langQueryParameter: string | undefined,
    cookieValue: string | undefined,
    navigatorLanguage: string | undefined,
): Locale {
    // try get locale from lang query parameter
    if (langQueryParameter) {
        const langQueryParamLocale = languageToLocale(langQueryParameter);

        if (langQueryParamLocale) {
            return langQueryParamLocale;
        }
    }

    // try get locale from cookie
    if (cookieValue) {
        const cookieLocale = languageToLocale(cookieValue);

        if (cookieLocale) {
            return cookieLocale;
        }
    }

    // try get locale from navigator.language
    if (navigatorLanguage) {
        const navigatorLocale = languageToLocale(navigatorLanguage);

        if (navigatorLocale) {
            return navigatorLocale;
        }
    }

    // fallback to default locale
    return defaultLocale;
}

/**
 * Gets the locale from a language string e.g. "en-US"
 */
function languageToLocale(languageString: string): Locale | null {
    const [language, subtag] = languageString.toLowerCase().trim().split('-');

    if (!language) {
        return null;
    }

    switch (language) {
        case 'en': {
            return 'en';
        }

        case 'fr': {
            switch (subtag) {
                case 'ca': {
                    return 'fr-CA';
                }

                default: {
                    return 'fr-CA';
                }
            }
        }

        default: {
            return null;
        }
    }
}
