import langEn from 'lang/en.json';
import langFrCa from 'lang/fr-CA.json';

export type Locale = 'en' | 'fr-CA';

export const allLocales: Locale[] = ['en', 'fr-CA'];

export const defaultLocale: Locale = 'en';

export const messagesByLocale: Record<Locale, Record<string, string>> = {
    en: langEn,
    'fr-CA': langFrCa,
};

export const getLocaleName: Record<Locale, string> = {
    en: 'English',
    'fr-CA': 'Français (Canada)',
};

export function validateLocale(value: string | null | undefined): Locale | null {
    if (!value) {
        return null;
    }

    const valueLowercase = value.toLowerCase();

    for (const locale of allLocales) {
        if (locale.toLowerCase() === valueLowercase) {
            return locale;
        }
    }

    return null;
}
