/**
 * Inject values from environment variables into NextJS runtime configuration
 * Environment variables can be defined in `.env.runtime.XXX` files which are loaded by dotenv-cli during app startup, see `./appservice-start.sh`
 *
 * Loaded into NextJS by `next.config.js` `publicRuntimeConfig`
 * https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
 *
 * To consume, use `getRuntimeConfig()` or `getRuntimeConfigRequired()` see `next.publicRuntimeConfig.ts`
 *
 * IMPORTANT NOTE: all values defined here WILL BE rendered as CLEAR TEXT into the CLIENT HTML output
 * DO NOT REFERENCE ANY SECRET VALUES HERE
 */

module.exports = {
    /**
     * The DSN for Sentry logging (identifies the project)
     */
    sentryDsn: process.env['SENTRY_DSN'],
    /**
     * The environment name for Sentry logging (e.g. "Staging" or "Production")
     */
    sentryEnvironment: process.env['SENTRY_ENVIRONMENT'],
    /**
     * Cloudflare Analytics token
     */
    cloudflareAnalyticsToken: process.env['CLOUDFLARE_ANALYTICS_TOKEN'],
    /**
     * Google Analytics ID
     */
    googleAnalyticsId: process.env['GOOGLE_ANALYTICS_ID'],
    /**
     * OneTrust cookie banner ID
     */
    oneTrustId: process.env['ONETRUST_ID'],
};
